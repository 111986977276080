import { useState, useEffect } from "react";
import {
  determineBreakPoints,
  getWindowDimensions,
  BreakPointType,
} from "./helpers";

const useBreakPoints = () => {
  const [breakPoint, setCurrentBreakPoint] = useState<BreakPointType>(() =>
    determineBreakPoints(getWindowDimensions())
  );

  useEffect(() => {
    const onResize = () => {
      const currentBreakPoint = determineBreakPoints(getWindowDimensions());
      if (currentBreakPoint.breakPoint !== breakPoint.breakPoint) {
        setCurrentBreakPoint(currentBreakPoint);
      }
    };
    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [breakPoint.breakPoint]);

  return breakPoint;
};

export default useBreakPoints;
