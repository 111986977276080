export const colors = {
  brand: {
    darker: "#363636",
    dark: "#474747",
    light: "#A8A7A7",
    primary: "#E8175D",
    secondary: "#CC527A",
    republican: "rgba(178,34,52, .85)",
    democrat: "rgba(60, 59, 110, .85)",
    neutral: "rgba(170,170,170, 1)",
    mixedParties: "rgba(91, 4, 146, .85)",
    independent: "rgba(93,155,89,.85)",
  },
  font: {
    black: "rgba(0,0,0, 0.8)",
  },
};

export const breakpointsPX = {
  base: 16,
  sm: 480,
  md: 769,
  lg: 992,
  xl: 1280,
  "2xl": 1536,
};

export const THEME = {
  colors,
  breakpointsPX,
};
