import { AnyAction } from "redux";

const initialState = {};

const authReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case "login":
      return state;
    default:
      return state;
  }
};

export { authReducer };
