import { breakpointsPX } from "./../../styles";

export enum BREAK_POINTS {
  XX_LG = "2xl",
  X_LG = "xl",
  LG = "lg",
  MD = "md",
  SM = "sm",
  BASE = "base",
}

export type WindowDimensionsType = {
  width: number;
  height: number;
};

export type BreakPointType = {
  width: number;
  breakPoint: `${BREAK_POINTS}`;
};

export const getWindowDimensions = (): WindowDimensionsType => {
  const { innerWidth: width, innerHeight: height } = window;

  return {
    width,
    height,
  };
};

export const determineBreakPoints = ({
  width,
}: WindowDimensionsType): BreakPointType => {
  if (width > breakpointsPX[BREAK_POINTS.XX_LG]) {
    return {
      width,
      breakPoint: BREAK_POINTS.XX_LG,
    };
  } else if (width > breakpointsPX[BREAK_POINTS.X_LG]) {
    return {
      width,
      breakPoint: BREAK_POINTS.X_LG,
    };
  } else if (width > breakpointsPX[BREAK_POINTS.LG]) {
    return {
      width,
      breakPoint: BREAK_POINTS.LG,
    };
  } else if (width > breakpointsPX[BREAK_POINTS.MD]) {
    return {
      width,
      breakPoint: BREAK_POINTS.MD,
    };
  }
  return {
    width,
    breakPoint: BREAK_POINTS.SM,
  };
};
