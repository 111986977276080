import { keyframes } from "@emotion/react";

const slideIn = keyframes`
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0%);
  }
`;

export { slideIn };
